<template>
  <v-footer padless>
    <v-col class="text-center" cols="12">
      <strong>קבוצת פרסום 10</strong>
      &nbsp;—&nbsp;
      {{ new Date().getFullYear() }}
      <template v-if="authenticated">
        <br>
        (מהדורה: 0.58-{{ this.user.profile_id }})
      </template>
    </v-col>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainFooter",
  props: {
    color: String,
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'user']),
  },
  methods: {},
};
</script>

<style scoped>
</style>
