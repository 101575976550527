var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":this.headers,"items":this.items,"items-per-page":this.itemsPerPage,"loading":this.loading,"item-key":this.itemKey},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){return [_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.quickViewDialog),callback:function ($$v) {_vm.quickViewDialog=$$v},expression:"quickViewDialog"}},[_c('ProductItemQuickView',{attrs:{"productItem":_vm.quickViewProductItem}})],1)]}},{key:"item.body",fn:function(ref){
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._s(value)}})]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)),_c('br'),(['1','2','3','4','5'].includes(_vm.user.profile_id))?_c('a',{attrs:{"href":'https://www.plat.co.il/product/'+item.nid,"target":"_blank","title":"לפלטפורמה","fab":"","small":""}},[_vm._v(" לצפייה בפלטפורמה ")]):_vm._e()]}},{key:"item.picture",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value !== '')?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"150","color":"grey lighten-4"}},[_c('v-img',{attrs:{"height":"150","max-width":"150","contain":"","src":value},on:{"click":function($event){return _vm.openQuickViewDialog(item.nid)}}}),_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out blue darken-2 v-card--reveal text-h2 white--text",staticStyle:{"height":"100%"},on:{"click":function($event){return _vm.openQuickViewDialog(item.nid)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-magnify")]),(item.pics.length > 1)?_c('div',{staticClass:"caption"},[_vm._v(" לתמונות נוספות ")]):_vm._e()],1):_vm._e()])],1)]}}],null,true)}):_vm._e()]}},_vm._l((_vm.headers.filter(function (x) { return x.value.startsWith('pr'); })),function(column){return {key:("item." + (column.value)),fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}}})],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }