import Vue from 'vue'
import Vuex from 'vuex'
import authState from './store/auth';
import productsState from './store/products';


Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		auth: {
			namespaced: true,
			...authState,
		},
		products: {
			namespaced: true,
			...productsState,
		},
	},
	//plugins: []
})
