<template>
  <!-- <v-container fill-height fluid1 grid-list-md text-xs-center> -->
  <!-- <v-layout row wrap> -->
    <div>
    <v-app-bar
      app
      elevation="4"
    >
    <v-app-bar-nav-icon v-if="authenticated" @click="drawer=true" ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="@/assets/p10-logo.webp"
          transition="scale-transition"
          width="130"
        />

        <!-- <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
      </div>

      <!-- <v-spacer></v-spacer>
      <v-btn v-if="authenticated"
        color="accent"
        rounded class=""
        large
        :to="{ name: 'products'}"
      >
        {{ $t('common.products') }}
      </v-btn> -->

      <v-spacer></v-spacer>
      <template v-if="authenticated">
        <!-- {{ $t('hello.user.message1', { fullName: this.user.p10_data.full_name, compName: this.user.p10_data.company_name }) }} -->
        {{ $t('common.hello') }}&nbsp;
        <strong>{{ this.user.full_name }}</strong>&nbsp;
        {{ $t('from-company') }}&nbsp;
        <strong>{{ this.user.company_name }}</strong>
        <v-spacer></v-spacer>
      </template>

      <!-- <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn> -->
      <v-btn v-if="!authenticated"
        color="accent"
        rounded class=""
        large1
        tile
        :to="{ name: 'login'}"
      >
        <v-icon left>
          mdi-account
        </v-icon>
        {{ $t('auth.login-btn-txt') }}
      </v-btn>

      <div v-if="authenticated" class="d-inline-flex pa-2">
        <v-container>
          <v-row>
            <v-col>

              <v-btn
                color="accent"
                rounded class=""
                tile
                @click="doLogout"
              >
                <v-icon left>
                  mdi-account
                </v-icon>
                {{ $t('common.logout-txt') }}
              </v-btn><br/>
              מהדורה: 0.58-{{ this.user.profile_id }}
            </v-col>
          </v-row>
        </v-container>
      </div>

    </v-app-bar>
    <!-- <v-app-bar app1 color="primary" dark flat height="80">
      <v-row align="center"  class="py-1">
        <v-col cols="2">
          <v-app-bar-nav-icon v-if="this.isLoggedUser" @click="drawer=true"></v-app-bar-nav-icon>
        </v-col>
        <v-col cols="8" >
        <router-link class="" to="/">
          Padmin
        </router-link>
      </v-col>

      <v-col cols="2" class="d-flextt justify-endtt py-10">
        more
      </v-col>
      </v-row>
    </v-app-bar> -->

    <v-navigation-drawer v-if="authenticated" app  v-model="drawer" temporary right>
      <!-- color="primary" -->
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="">
          <v-list-item v-for="(item, index) in menuItems" :key="index" class="icon-item"
            link
            color="success"
            :to="item.path ? { path: item.path } : null"
            :href="!item.path ? item.url : null"
            :target="!item.path ? '_blank' : null"
            >
            <v-list-item-icon >
              <v-icon v-if="item.iconCode">{{ item.iconCode }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title color="white1">{{ item.label }}</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block color="accent" rounded @click="doLogout">
            {{ $t('common.logout-txt') }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  <!-- </v-layout> -->
  </div>
  <!-- </v-container> -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainNavbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {},
  data() {
    return {
      //isLoggedUser: true,
      drawer: false,
      group: null,
    }
  },
  computed: {
    ...mapGetters('auth', ['authenticated', 'user']),
    menuItems() {
      //console.log(this.user)
      return [
        /*{
          "icon": false,
          "iconCode": "mdi-home-variant-outline",
          "label": "Leads",
          "path": "/leads"
        },*/
        {
          icon: false,
          iconCode: 'mdi-file-table-box-outline',
          label: this.$t('common.products'),
          path: '/products'
        }
      ]
      //return [lobbyItem, ...this.consts.menuItems]
      //return [lobbyItem]
    },
  },
  methods: {
    doLogout(e) {
      e.preventDefault();
      this.$store.dispatch('auth/logout');
      //this.$router.replace({ name: 'login' })
    },
  },
  mounted() {
    //console.log(this.user)
  },
};
</script>

<style scoped>
</style>
