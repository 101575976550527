import Vue from 'vue'
import router from "@/router";

const productsState = {
	state: {
		inAuthProc: 'init',// init, codeSent, done, error
		busy: false,
		errorMessage: '',
		infoMessage: '',
		authenticated: false,
		user: null,
	},
	mutations: {
		setInAuthProc(state, val) {
			return state.inAuthProc = val
		},
		setBusy(state, val) {
			return state.busy = val
		},
		setErrorMessage(state, val) {
			return state.errorMessage = val
		},
		setInfoMessage(state, val) {
			return state.infoMessage = val
		},
		setAuthenticated(state, val) {
			return state.authenticated = val
		},
		setUser(state, val) {
			return state.user = val
		},
	},
	actions: {
		//async init({ dispatch, commit, state }, initData) {
		async requestEmailLogin({ commit }, formData) {
			commit('setBusy', true)
			await Vue.prototype.axios.get('/sanctum/csrf-cookie')
			await Vue.prototype.axios.post('/api/request_login', formData).then(() => {
				commit('setErrorMessage', '')
				commit('setInfoMessage', 'code_sent')
				commit('setInAuthProc', 'codeSent')
				commit('setBusy', false)
				//console.log(response)
			}).catch((error) => {
				//console.log(error)
				//console.log(error.code)
				//console.log(error.message)
				//console.log(error.stack)
				//commit('setInAuthProc', 'error')
				if (error.code == 'ECONNABORTED') {
					commit('setErrorMessage', 'request_timeout')
					commit('setInfoMessage', '')
				} else if (error.response) {
					commit('setErrorMessage', error.response.data.message)
					commit('setInfoMessage', '')
				}
				commit('setInAuthProc', 'init')
				commit('setBusy', false)
			});
		},
		async loginWithCode({ commit, dispatch }, formData) {
			commit('setBusy', true)
			await Vue.prototype.axios.get('/sanctum/csrf-cookie')
			//.then(response => { console.log(response) })
			await Vue.prototype.axios.post('/api/login_with_code', formData).then(() => {
				//console.log(response.data)
				commit('setErrorMessage', '')
				commit('setInfoMessage', 'auth_complt')
				commit('setInAuthProc', 'done')
				commit('setBusy', false)
				return dispatch('me')
				//commit('setAuthenticated', true)
				//commit('setUser', response.data.user)
			}).catch((error) => {
				if (error.code == 'ECONNABORTED') {
					commit('setErrorMessage', 'request_timeout')
					commit('setInfoMessage', '')
				} else if (error.response) {
					commit('setErrorMessage', error.response.data.message)
					commit('setInfoMessage', '')
				}
				//commit('setInAuthProc', 'error')
				commit('setBusy', false)
			})
		},
		async logout({ dispatch }) {
			await Vue.prototype.axios.post('/api/logout')
			return dispatch('me')
		},
    me ({ commit }) {
			return Vue.prototype.axios.get('/api/user').then((response) => {
				commit('setAuthenticated', true)
				commit('setUser', response.data)
				commit('setInAuthProc', 'done')
				//this.$router.replace({ name: 'products' })
				router.replace({ name: 'products' })
			}).catch(() => {
				commit('setAuthenticated', false)
				commit('setUser', null)
				commit('setInAuthProc', 'init')
				//this.$router.replace({ name: 'login' })
				router.replace({ name: 'login' })
			})
		},
	},
	getters: {
		inAuthProc(state) {
			return state.inAuthProc
		},
		busy(state) {
			return state.busy
		},
		errorMessage(state) {
			return state.errorMessage
		},
		infoMessage(state) {
			return state.infoMessage
		},
		authenticated(state) {
			return state.authenticated
		},
		user(state) {
			return state.user
		},
	},
}

export default productsState
