import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import router from "./router";
import Vuex from 'vuex'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
//import VueI18n from 'vue-i18n'

import KpMultiFiltersPlugin from '@/plugins/kp/KpMultiFilters'
import i18n from './i18n'

Vue.config.productionTip = false

Vue.use(Vuex)
const axiosInstance = axios.create({
  //baseURL: 'http://localhost/vue/pir10/padmin3/server/lara_server/public/',
  //baseURL: 'http://localhost/vue/pir10/padmin3/server/lara_server3/public/',
  //baseURL: 'http://127.0.0.1:8000',
  //baseURL: 'http://localhost:8000',// this is for the local
  baseURL: (process.env.NODE_ENV === 'development') ? 'http://localhost:8000' : 'https://p10clients.pirsum10.co.il/lara_server3/public/',
  // baseURL: 'https://p10clients.pirsum10.co.il/lara_server3/public/',
  headers: {
    //authorization: `Bearer ${token}`
  },
  timeout: 20000,
});
axiosInstance.defaults.withCredentials = true;
Vue.use(VueAxios, axiosInstance)
//Vue.use(VueI18n)

Vue.use(KpMultiFiltersPlugin);

store.dispatch('auth/me').then(() => {
  new Vue({
    vuetify,
    store,
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})
