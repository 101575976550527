<template>
  <v-container fill-height fluid>
    <template v-if="!doneInit">Loading...</template>
    <template v-else>
      <v-row>
        <v-col v-for="[filterItemId, filterItem] in Object.entries(this.filters)" :key="filterItemId" cols=2>
          <template v-if="filterItem.widget == 'text'">
            <v-text-field
              v-model="filtersState[filterItemId]"
              :label=filterItem.label
            ></v-text-field>
          </template>
          <template v-else-if="filterItem.widget == 'select'">
            <v-select
              v-model="filtersState[filterItemId]"
              :label=filterItem.label
              :items="filterItem.selOptions"
              multiple
            ></v-select>
          </template>
        </v-col>
        <v-col cols=2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" >
          <ProductsTable
            :headers="this.headers"
            :items="this.tableItems"
            :items-per-page="100"
            :loading="!doneInit"
            item-key="nid"
            >
          </ProductsTable>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import ProductsTable from '@/components/products/ProductsTable';
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'ProductsPage',
  components: {
    ProductsTable,
  },

  data: () => ({
    //valid: true,
      filtersState: {
        title: '',
        sku: '',
        body: '',
        fuseTitle: '',
      },
      filters: {
        fuseTitle: {
          sourceItemOrList: 'list',
          keys: ['title', 'bodyRaw', 'nid', 'sku'],
          label: 'חיפוש',
          widget: 'text',
          reduceFunc: 'nonEmptyTextFuseSearch'
        },
        title: {
          //id: 'title',
          sourceItemOrList: 'item',
          keys: ['title'],
          label: 'שם',
          widget: 'text',
          reduceFunc: 'nonEmptyTextSearch'
        },
        sku: {
          sourceItemOrList: 'item',
          keys: ['sku'],
          label: 'מק"ט',
          widget: 'text',
          reduceFunc: 'nonEmptyTextSearch'
        },
        body: {
          //id: 'body',
          sourceItemOrList: 'item',
          keys: ['body'],
          label: 'תאור',
          widget: 'text',
          reduceFunc: 'nonEmptyTextSearch'
        },
      },
      profileBaseColor: 'blue',
      profileIdToColor: [
        ['lighten-5', 'black--text'],
        ['lighten-4', 'black--text'],
        ['lighten-3', 'black--text'],
        ['lighten-2', 'black--text'],
        ['lighten-1', 'black--text'],
        ['darken-1', 'white--text'],
        ['darken-2', 'white--text'],
        ['darken-3', 'white--text'],
        ['darken-4', 'white--text'],
      ],
  }),

  computed: {
    ...mapGetters('products', ['doneInit', 'getProductsData', 'getStructData']),
    priceQtys: function() {
      return this.getStructData.list_qtys
    },
    headers: function() {
      let items = [];
      //let rawItems = this.getProductsData.header
      //items.push({ text: 'ID', value: 'nid' })
      items.push({ text: 'מקט', value: 'sku' })
      items.push({ text: 'תמונה', value: 'picture' })
      items.push({ text: 'שם', value: 'title' })
      items.push({ text: 'תאור', value: 'body' })
      items.push({ text: 'עלות SETUP והפקת דוגמא לכל סוג מוצר', value: 'setup_price' })

      //items.push({ text: 'Price', value: 'price' })
      this.priceQtys.forEach((qtyItm, index) => {
        if (qtyItm.val <= 1000) {
          items.push({
            text: qtyItm.label,
            value: 'pr0'+'-qty'+qtyItm.val,
            class: [this.profileBaseColor, this.profileIdToColor[index]],
            cellClass: [this.profileBaseColor, this.profileIdToColor[index]],
          })
        }
      })
      return items
    },
    rawItems: function () {
      //return this.getProductsData.rows
      let inItems = this.getProductsData//.slice(0, 200)
      //console.log(this.getProductsData)
      //let itemsWithPics = this.getProductsData.filter(x => x.pics.length > 0)
      //console.log(itemsWithPics)
      let items = inItems.map(x => {return {...x, bodyRaw: x.body.replace(/<[^>]*>?/gm, '')}})
      //let items = Product.all()
      //console.log(items)
      //let items = []
      //return items
      const cf = new this.$KpMultiFilters(items, this.filters, this.filtersState);

      //cf.registerFilter('title', (item, search) => {
        //if (search.trim() === '') return true;
        //return item.title.toLowerCase().includes(search.toLowerCase());
      //});
      /*this.filters.forEach((filter) => {
        cf.registerFilter(filter.id, filter.reduceFunc);
      })*/
      Object.entries(this.filters).forEach(([filterId, filter]) => {
        cf.registerFilter(filterId, filter.reduceFunc);
      })
      //cf.registerFilter('title', 'nonEmptyTextSearch');
      //cf.registerFilter('status', (item, search) => {
        //if (search.trim() === '') return true;
        //return item.status.toLowerCase().includes(search.toLowerCase());
      //});
      //console.log(cf)
      return cf.runFilters();
    },
    tableItems: function () {
      //let items = this.getProductsData.rows
      //console.log(this.rawItems)
      //let items = this.rawItems
      let items = this.rawItems.map(item => {
        let row = {};
        row.nid = item.nid;
        row.title = item.title;
        //row.title = item.title + '<a href="https://gifts.pirsum10.co.il/he/pir/product/" target="_blank"></a>';
        row.sku = item.sku;
        row.picture = item.picture;
        //row.body = this.$htmlToPaper(item.body);
        row.body = item.body;
        row.setup_price = item.setup_price;
        row.pics = item.pics;
        this.priceQtys.forEach((qtyItm) => {
          row['pr0'+'-qty'+qtyItm.val] = item.prices.unit_price_per_qty[qtyItm.val] || ''
          //row['pr0'+'-qty'+qtyItm.val] =  Object.prototype.hasOwnProperty.call(item.prices.unit_price_per_qty, qtyItm.val) ? item.prices.unit_price_per_qty[qtyItm.val] : ''
        })

        return row;
      });
      return items
    },


  },
  methods: {
    ...mapActions('products', ['init']),
    getUserInfo() {
    },
  },
  async mounted() {
    //console.log("in product mount");
    if (!this.doneInit) {
      await this.init();
      //console.log("start init");
    }
  },
}
</script>
