<template>
  <v-container >
    <template v-if="!doneInit">Loading...</template>
    <template v-else>
      <v-row class="mt-4">
        <v-col cols=6 class="ml-md-auto">
          <v-sheet height="100%" tile >
            <!-- <v-row class="fill-height" align="center" justify="center" > -->
            <v-row >
              <v-carousel v-model="currentPicInSlide">
                <v-carousel-item v-for="(pic,i) in pictures" :key="i"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                  <v-img height="100%" contain :src="pic.large1">
                  </v-img>
                </v-carousel-item>
              </v-carousel>
            </v-row>
            <v-row>
              <v-col v-for="(pic,i) in pictures" :key="i" cols=2 @click="currentPicInSlide = i">
                <v-img height="150" contain :src="pic.teaser1"></v-img>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col cols=6 class="ml-md-auto">
            <h1 class="my-4">
              {{ productItem.title }}
            </h1>
            <div class="my-2">
              {{ $t('product.sku') }}:&nbsp;{{ productItem.sku }}
            </div>
            <div class="my-8" v-html="productItem.body">
            </div>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'ProductItemPage',
  components: {},
  props: {
    productId: String,
  },

  data: () => ({
    //valid: true,
    cycle: false,
    currentPicInSlide: 0,
  }),

  computed: {
    ...mapGetters('products', ['doneInit', 'getProductsData', 'getStructData']),
    productItem: function () {
      return this.getProductsData.find(x => x.nid == this.productId)//28373
    },
    pictures: function () {
      //console.log(this.productItem)
      return this.productItem.pics
    },
  },
  methods: {
    ...mapActions('products', ['init']),
    getUserInfo() {
    },
  },
  async mounted() {
    if (!this.doneInit) {
      await this.init();
    }
  },
}
</script>
