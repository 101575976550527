/**
 * Enabled v-data-table to have moire than one filter.
 */
// source: https://github.com/frontid/vuetify-data-table-multi-filter
import Fuse from 'fuse.js'
class KpMultiFilters {

  /**
   * Constructor.
   *
   * @param items
   * @param filters
   * @param filter
   * @param headers
   */
  //constructor(items, filters, filter, headers) {
  constructor(items, filterConf, filtersState) {
    this.items = items;
    //this.filter = filter;
    //this.headers = headers;
    //this.filters = filters;
    this.filterConf = filterConf;
    this.filtersState = filtersState;
    this.filterCallbacks = {};

    this.basicCbs = {
      /*nonEmptyTextSearch: (item, field, search) => {
        console.log(field)
        if (search.trim() === '') return true;
        //return item.title.toLowerCase().includes(search.toLowerCase());
        if (!item[field] || item[field].length == 0) return false;
        return item[field].toLowerCase().includes(search.toLowerCase());
      },*/
      nonEmptyTextSearch: (item, field, filterConf, filtersState) => {
        let search = filtersState[field]
        const searchInKey = filterConf[field].keys[0]
        if (search == null) return true;
        if (search.trim() === '') return true;
        if (!item[searchInKey] || item[searchInKey].length == 0) return false;
        return item[searchInKey].toLowerCase().includes(search.toLowerCase());
      },
      /*nonEmptyTextFuseSearch: (item, field, filterConf, filtersState) => {
        let search = filtersState[field]
        //console.log(search)
        //const searchInKey = filterConf[field].keys[0]
        if (search.trim() === '') return true;
        //if (!item[searchInKey] || item[searchInKey].length == 0) return false;
        const options = {
          includeScore: true,
          keys: filterConf[field].keys
        }
        let fuse = new Fuse([item], options)
        //console.log(fuse)
        const result = fuse.search(search)
        console.log(result)
        return result.map(x => x.item).length > 0
      },*/
      nonEmptyTextFuseSearch: (items, field, filterConf, filtersState) => {
        let search = filtersState[field]
        //console.log(items)
        //const searchInKey = filterConf[field].keys[0]
        if (search.trim() === '') return items;
        //if (!item[searchInKey] || item[searchInKey].length == 0) return false;
        const options = {
          includeScore: true,
          threshold: 0.8,
          distance: 2000,
          keys: filterConf[field].keys
          //keys: ['bodyRaw']
        }
        let fuse = new Fuse(items, options)
        //console.log(fuse)
        const result = fuse.search(search)
        //console.log(result)
        return result.map(x => x.item)
      },
    }
  }

  /**
   * Updates filter values.
   * @param filters filter´s object
   * @param val JSON chunk to be updated.
   * @returns {*}
   */
  static updateFilters(filters, val) {
    return Object.assign({}, filters, val);
  }

  /**
   * Adds a new filter
   * @param filterId The name of the filter from which the information will be extracted
   * @param filterCallback The callback that will apply the filter.
   */
  registerFilter(filterId, filterCallback) {
    //console.log(typeof filterCallback)
    if (typeof filterCallback === 'function') {
      this.filterCallbacks[filterId] = filterCallback;
    } else if (typeof filterCallback === 'string') {
      if (Object.prototype.hasOwnProperty.call(this.basicCbs, filterCallback)) {
        this.filterCallbacks[filterId] = this.basicCbs[filterCallback];
      }
    }
  }

  /**
   * Run all filters.
   * @returns {*}
   */
  runFilters() {
    const self = this;
    let filteredItems = self.items;

    /*Object.entries(this.filterCallbacks)
      .forEach(([entity, cb]) => {
        filteredItems = cb.call(self, self.filters[entity], filteredItems);
      });*/
    Object.entries(this.filterCallbacks).forEach(([filterId, cb]) => {
      //console.log(self.filterConf[filterId].sourceItemOrList)
      if (self.filterConf[filterId].sourceItemOrList == 'item') {
        //console.log([entity, cb])
        //filteredItems = cb.call(self, self.filters[entity], filteredItems);
        //cb.call(self, filteredItems, self.rowFilters[entity]);
        //filteredItems = filteredItems.filter(item => { cb.call(self, item, entity, self.rowFilters[entity])});
        //filteredItems = filteredItems.filter(item => { cb.call(self, item, self.rowFilters[entity])});
        //filteredItems = filteredItems.filter(item => cb.call(self, item, this.rowFilters[entity]) );
        //filteredItems = filteredItems.filter(item => cb.call(this, item, this.rowFilters[entity]));
        //filteredItems = filteredItems.filter(item => cb.call(self, item, filterId, self.filtersState[filterId]));
        filteredItems = filteredItems.filter(item => cb.call(self, item, filterId, self.filterConf, self.filtersState));
        //filteredItems.filter(item => {
      } else {
        filteredItems = cb.call(self, filteredItems, filterId, self.filterConf, self.filtersState);
      }
    });


    return filteredItems;
  }

}

// Vue plugin.
const KpMultiFiltersPlugin = {
  install(Vue) {
    Vue.prototype.$KpMultiFilters = KpMultiFilters;
  }
};

export default KpMultiFiltersPlugin;
