<template>
  <v-data-table
    :headers="this.headers"
    :items="this.items"
    :items-per-page="this.itemsPerPage"
    :loading="this.loading"
    class="elevation-1"
    :item-key="this.itemKey"
    >
    <template v-slot:[`body.prepend`]="{}">
      <v-dialog v-model="quickViewDialog" width="500" >
        <ProductItemQuickView :productItem="quickViewProductItem"></ProductItemQuickView>
      </v-dialog>
    </template>



    <!-- :custom-filter="customFilter" -->
    <!-- :search="filtersState" -->
    <template v-slot:[`item.body`]="{value}">
        <span v-html="value"></span>
    </template>

    <!-- <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/>a</template> -->
    <!-- <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/>{{slot}}</template> -->
    <template v-slot:[`item.title`]="{ item }">
      {{ item.title }}<br/>
        <a v-if="['1','2','3','4','5'].includes(user.profile_id)" :href="'https://www.plat.co.il/product/'+item.nid" target="_blank" title="לפלטפורמה" fab small>
          <!-- <v-icon>mdi-format-paragraph</v-icon> -->
          לצפייה בפלטפורמה
        </a>
        <!-- <v-btn color="primary" :to="{ name: 'productItemPage', params: { productId: item.nid }}" >View</v-btn> -->
        <!-- <v-btn
          color="primary"
          class="mx-2"
          fab small dark
          @click="openQuickViewDialog(item.nid)"
        >
          <v-icon dark>mdi-magnify</v-icon>
        </v-btn> -->
    </template>

    <template v-slot:[`item.picture`]="{ value, item }">
      <v-hover v-if="value !== ''" v-slot="{ hover }">
        <v-card max-width="150" class="mx-auto" color="grey lighten-4">
          <v-img
            height="150"
            max-width="150"
            contain
            :src="value"
            @click="openQuickViewDialog(item.nid)"
          ></v-img>
          <v-expand-transition>
            <div
              v-if="hover"
              class="d-flex transition-fast-in-fast-out blue darken-2 v-card--reveal text-h2 white--text"
              style="height: 100%;"
              @click="openQuickViewDialog(item.nid)"
            >
              <v-icon dark>mdi-magnify</v-icon>
              <div v-if="item.pics.length > 1" class="caption">
                לתמונות נוספות
              </div>
            </div>
          </v-expand-transition>
        </v-card>
      </v-hover>
    </template>

    <template v-for="(column) in headers.filter(x => x.value.startsWith('pr'))" v-slot:[`item.${column.value}`]="{ value }" >
      <!-- :class="{'first': true}" -->
      <!-- <div :key="i" class="price-cell" :class="[{'first': (i == 0)}, column.value.split('-')]">
        {{ `item.${column.value}` }}
        {{ item.nid }} -->
        {{ value }}
      <!-- </div> -->
    </template>

  </v-data-table>
</template>

<script>
import ProductItemQuickView from '@/components/products/ProductItemQuickView';
import { mapGetters } from "vuex";

export default {
  name: 'ProductsTable',
  components: {
    ProductItemQuickView
  },
  props: {
    headers: Array,
    items: Array,
    itemsPerPage: Number,
    loading: Boolean,
    itemKey: String,
  },
  data() {
    return {
      quickViewDialog: false,
      quickViewProductItem: {},
      //hover: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    openQuickViewDialog(productId) {
      //console.log(productId)
      this.quickViewProductItem = this.items.find(x => x.nid == productId)
      this.quickViewDialog = true
    },
  },
  watch: {},
};
</script>

<style>
/* .pic-reveal { */
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .8;
  position: absolute;
  width: 100%;
}
</style>
