<template>
  <v-container fill-height>
    <!-- <v-row class="ma-6"></v-row> -->
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" >
        <v-card elevation="4">
          <v-container>
            <v-row v-if="!authenticated">
              <v-spacer></v-spacer>
              <v-col cols="12" md="10">
                <v-form ref="form" v-model="valid" lazy-validation >


                  <v-fade-transition>
                    <v-overlay absolute :value="busy">
                  <!-- <v-overlay :value="overlay"> -->
                    <v-progress-circular
                      indeterminate
                      size="64"
                    ></v-progress-circular>
                  </v-overlay>
                  </v-fade-transition>

                  <div class="text-h4 mb-4">
                    כניסה לחשבון שלי
                  </div>
                    <v-alert
                      :value="errorMessage.length > 0"
                      border="left"
                      type="error"
                      transition="scale-transition"
                    >
                      {{ messageLookup[errorMessage] }}
                    </v-alert>

                    <v-alert
                      :value="infoMessage.length > 0"
                      border="left"
                      type="success"
                      transition="scale-transition"
                    >
                      {{ $t(messageLookup[infoMessage]) }}
                    </v-alert>
                  <template v-if="inAuthProc == 'init'">
                    <div class="body-1 mb-4">
                      התחברות למערכת באמצעות קוד זיהוי הנשלח לכתובת המייל
                    </div>
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      required
                      :label="$t('common.email-address')"
                    ></v-text-field>
                      <!-- @input="validate('email')"
                      :required="this.phone.length == 0"
                      :error-messages="errorEmail" -->
                      <!-- :rules="emailValidationRules" -->
                    <v-text-field
                      v-model="phone"
                      @input="validate('phone')"
                      :label="$t('common.phone-number')"
                      :required="this.email.length == 0"
                      :error-messages="errorPhone"
                    ></v-text-field>
                      <!-- :rules="phoneValidationRules" -->
                      <!-- :rules="phoneRules" -->
                    <v-btn
                      :disabled="!valid"
                      color="success"
                      class="mb-4"
                      type="submit"
                      @click="requestEmailLoginSubmit"
                    >
                      {{ $t('auth.request-email-code-via-email-btn-txt') }}
                    </v-btn>
                  </template>

                  <template v-if="inAuthProc == 'codeSent'">
                    <v-text-field
                      v-model="code"
                      :counter="6"
                      :rules="codeRules"
                      :label="$t('common.code')"
                      required
                    ></v-text-field>
                    <v-btn
                      :disabled="!valid"
                      color="success"
                      class="mb-4"
                      type="submit"
                      @click="loginWithCodeSubmit"
                    >
                      {{ $t('auth.login-with-code-btn-txt') }}
                    </v-btn>
                  </template>

                  <!-- <v-btn
                    :disabled="!valid"
                    color="success"
                    class="mr-4"
                    @click="getUserInfo"
                  >
                    User info
                  </v-btn> -->
                </v-form>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from "vuex";

export default {
  name: 'LoginForm',

  data: () => ({
    valid: true,
    //email: 'shalev162@gmail.com',
    email: '',
    phone: '',
    /*emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],*/
    code: '',
    //codeSent: false,
    messageLookup: {
      'Email not found': 'auth.errors.email-not-found',
      "Email or Phone not found": 'אימייל או טלפון לא נמצאו',
      'request_timeout': 'יש לנו בעיית תקשורת...',
      'code_sent': 'הקוד נשלח',
      'auth_complt': 'אימות הושלם',
      'wrong_code': 'קוד שגוי',
    },
    emailValidationRules: [true],
    phoneValidationRules: [true],
    errorEmail: '',
    errorPhone: '',
  }),
  computed: {
    ...mapGetters('auth', ['authenticated', 'inAuthProc', 'busy', 'errorMessage', 'infoMessage']),
    //emailRules: function () {
    emailRules() {
      //console.log(val)
      //console.log(form.email)
      //console.log(form.phone)
      //console.log(this.phone)
      return [
        v => !!v || this.$t('auth.errors.email-is-required'),
        v => /.+@.+\..+/.test(v) || this.$t('auth.errors.email-must-be-valid'),
      ]
      /*return [
        //v => !(!!v || !!this.phone) || 'mail or phone required',
        v => !(!v && !this.phone) || 'mail or phone required',
        v => !v || (v && /.+@.+\..+/.test(v.trim())) || this.$t('auth.errors.email-must-be-valid'),
      ]*/
    },
    phoneRules: function () {
      console.log(this.email)
      return [
        //v => !(!!v || !!this.email) || 'mail or phone required',
        v => !(!v && !this.email) || 'mail or phone required',
        v => !v || (v && /^[0-9-]+$/.test(v.trim())) || 'phone-must-be-valid',
      ]
    },
    codeRules: function () {
      return [
        v => !!v || this.$t('auth.errors.code-is-required'),
        v => /^[0-9]+$/.test(v) || this.$t('auth.errors.code-must-be-numbers'),
        v => v.length == 6 || this.$t('auth.errors.code-6-digits-only'),
      ]
    },
    overlay: function () {
      return this.busy
      //return false
    },

  },
  watch: {
    // watch for change and then validate
    //email: 'validatePhone',
    //phoneValidationRules: 'validate',
    /*errorMessage: {
      deep: true,
      handler: function (modVal) {
        console.log(modVal)
      }
    }*/
  },
  methods: {
    ...mapActions('auth', ['requestEmailLogin', 'loginWithCode']),
    validate: function (index) {
      // console.log(index)
      let noInput = !this.email && !this.phone
      this.errorEmail = noInput ? 'mail or phone required' : ''
      this.errorPhone = noInput ? 'mail or phone required' : ''

      if (index == 'phone') {
        this.errorPhone = this.phone && /^[0-9-]+$/.test(this.phone.trim()) ? '' : 'phone-must-be-valid'
      } else if (index == 'email') {
        this.errorEmail = this.email && /.+@.+\..+/.test(this.email.trim()) ? '' : this.$t('auth.errors.email-must-be-valid')
      }
      //Vue.set(this, 'emailValidationRules', ['123th avenue.']);
      //this.phoneValidationRules = [...this.phoneValidationRules]
      //this.phoneValidationRules = ['OK']
    },
    validatePhone() {
      //this.phoneValidationRules = ['OK1']
      //this.phoneValidationRules.splice(0, 1, this.phoneValidationRules[0])
      //this.phone = '05058'
      Vue.set(this.phoneValidationRules, 0, 'asdf')
    },
    requestEmailLoginSubmit(e) {
      e.preventDefault()
      const formValid = this.$refs.form.validate()
      // console.log(this.phone)
      if (formValid) {
        //this.submitEmailLogin()
        //this.requestEmailLogin({email: this.email})
        // this.requestEmailLogin({email: this.phone || this.email })
        this.requestEmailLogin({ email: this.email, phone: this.phone })
      }
    },
    /*submitEmailLogin() {
      this.axios.post('/api/request_login', {email: this.email}).then(response => {
        console.log(response.data)
        this.codeSent = true
      });
    },*/
    async loginWithCodeSubmit(e) {
      e.preventDefault()
      /*this.axios.get('/sanctum/csrf-cookie').then(response => {
        console.log(response)
        this.axios.post('/api/login_with_code', {email: this.email, code: this.code}).then(response => {
          console.log(response.data)
          //this.codeSent = true
        });
      });*/
      let sendParams = {
        code: this.code
      }
      if (this.email.length) {
        sendParams.email = this.email
      }
      if (this.phone.length) {
        sendParams.phone = this.phone
      }
      await this.loginWithCode(sendParams)
      //this.$router.replace({ name: 'products' })
    },
    getUserInfo() {
      //this.axios.get('/sanctum/csrf-cookie').then(response => {
        //console.log(response.data)
        //this.axios.get('/api/secret').then(response => {
        //this.axios.get('/api/books', {}, {'Accept': 'application/json'}).then(response => {
        this.axios.get('/api/books/products', {}, {'Accept': 'application/json'}).then(response => {
          console.log(response.data)
          //this.userInfo = response.data
        });
      //});
    },

  },
}
</script>
