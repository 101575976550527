<template>
  <v-container fill-height fluid>
    <v-row>
      <v-col cols=12>
        Home
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//import { mapGetters, mapActions } from "vuex";

export default {
  name: 'HomePage',
  components: {
  },

  data: () => ({
    //valid: true,
  }),

  computed: {
    //...mapGetters('products', ['doneInit', 'getProductsData', 'getStructData']),
  },
  methods: {
    //...mapActions('products', ['init']),
  },
  //async mounted() {},
  mounted() {
    //console.log(process.env.NODE_ENV)
  },
}
</script>
