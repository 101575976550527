import Vue from "vue";
import Router from "vue-router";
import MainNavbar from '@/components/MainNavbar';
import MainFooter from '@/components/MainFooter';
//import HelloWorld from './components/HelloWorld';
import HomePage from './components/HomePage';
import LoginForm from './components/LoginForm';
import ProductsPage from './components/products/ProductsPage';
import ProductItemPage from './components/products/ProductItemPage';
//import LoginPage from './pages/LoginPage';
import store from './store'


//import store from './store'

Vue.use(Router);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['auth/authenticated']) {
    next()
    return
  }
  //next('/')
  next({ name: 'home' })
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/authenticated']) {
    next()
    return
  }
  //next('/login')
  next({ name: 'login' })
}

const router = new Router({
  mode: 'history',
  //base: 'client',
  routes: [
    {
      path: "/",
      name: "home",
      components: {
        default: HomePage,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { transparent: false },
        footer: { color: 'trans' }
      },
    },
    {
      path: "/login",
      name: "login",
      components: {
        default: LoginForm,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { transparent: false },
        footer: { color: 'trans' }
      },
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/products",
      name: "products",
      components: {
        default: ProductsPage,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { transparent: false },
        footer: { color: 'trans' }
      },
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/products/:productId",
      name: "productItemPage",
      components: {
        default: ProductItemPage,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { transparent: false },
        footer: { color: 'trans' },
        default: route => ({
          productId: route.params.productId,
        })
      },
      beforeEnter: ifAuthenticated,
    },
    /*{
      path: "/login",
      name: "login",
      components: {
        default: LoginPage,
        header: MainNavbar,
        footer: MainFooter
      },
      props: {
        header: { transparent: false },
        footer: { color: 'trans' },
        default: route => ({
          dev: route.query.dev,
        })
      },
      meta: {
        hideForLogin: false
      },
      //beforeEnter: ifNotAuthenticated,
    },*/
  ],
  /*scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },*/
});

/*router.beforeEach((to, from, next) => {
  //var accessToken = localStorage.getItem('accessToken')
  //console.log(accessToken)
  if (!store.getters['gen/doneInit']) {
    store.dispatch('gen/init', {}).then(next)
      .catch(e => {
        console.log(e)
      })
  }
  if (to.matched.some(record => record.meta.requiresLogin) && !store.getters.isLoggedUser) {
  //if (to.matched.some(record => record.meta.requiresLogin) && (accessToken === null)) {
    next({ path: '/login' })
  } else if (to.matched.some(record => record.meta.hideForLogin) && store.getters.isLoggedUser) {
  //} else if (to.matched.some(record => record.meta.hideForLogin) && (accessToken !== null)) {
    next({ path: '/' })
  } else {
    next()
  }
})*/

export default router;
