<template>
  <v-card elevation="24" class="mx-auto">
    <v-carousel v-if="pictures.length" v-model="currentPicInSlide"
      :show-arrows="pictures.length > 1"
      :show-arrows-on-hover="pictures.length > 1"
      :hide-delimiters="pictures.length == 1"
    >
      <v-carousel-item v-for="(pic,i) in pictures" :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-img height="100%" contain :src="pic.large1">
        </v-img>
      </v-carousel-item>
    </v-carousel>
    <v-list two-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{ productItem.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t('product.sku') }}:&nbsp;{{ productItem.sku }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-text>
      <div class="my-8" v-html="productItem.body">
      </div>
    </v-card-text>
  </v-card>
      <!-- <v-row class="mt-4">
        <v-col cols=12 class="ml-md-auto">
          <v-sheet height="100%" tile >
            <v-row >
            </v-row>
            <v-row>
              <v-col v-for="(pic,i) in pictures" :key="i" cols=2 @click="currentPicInSlide = i">
                <v-img height="150" contain :src="pic.teaser1"></v-img>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols=12 class="ml-md-auto">
            <h1 class="my-4">
              {{ productItem.title }}
            </h1>
            <div class="my-2">
              {{ $t('product.sku') }}:&nbsp;{{ productItem.sku }}
            </div>
            <div class="my-8" v-html="productItem.body">
            </div>
        </v-col>
      </v-row>
    </template>
  </v-container> -->
</template>

<script>

export default {
  name: 'ProductItemQuickView',
  components: {},
  props: {
    productItem: Object,
  },

  data: () => ({
    //valid: true,
    cycle: false,
    currentPicInSlide: 0,
  }),

  computed: {
    pictures: function () {
      //console.log(this.productItem)
      return this.productItem.pics
    },
  },
  methods: {
  },
}
</script>
