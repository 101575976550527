import Vue from 'vue'

const productsState = {
	state: {
		doneInit: false,
		productsData: [],
		productsStruct: [],
	},
	mutations: {
		setDoneInit(state, val) {
			state.doneInit = val
		},
		setProductsData(state, data) {
			state.productsData = data.rows
			state.productsStruct = data.struct
		},
	},
	actions: {
		//async init({ dispatch, commit, state }, initData) {
		async init({ commit }) {
			await Vue.prototype.axios.get('/api/books/products', {}, { 'Accept': 'application/json' }).then(response => {
				//console.log(response.data.products)
				commit('setProductsData', response.data.products)
				commit('setDoneInit', true)
			})
			/*await axios.get('https://gifts.pirsum10.co.il/pir/product/rest/get?tok=gf0921md01', {//25944
			}, { withCredentials: true }).then(result => {
				if (result.data) {
					//console.log(result.data.event.all_item_list);
					commit('setProductsData', result.data)

					//commit('setEventSelsStruct', result.data.event.struct)
					//console.log('Got the sels')
					commit('setDoneInit', true)
				}
			}).catch(error => console.log(error))*/

		},
	},
	getters: {
		doneInit(state) {
			return state.doneInit
		},
		getProductsData(state) {
			return state.productsData
		},
		getStructData(state) {
			return state.productsStruct
		},
	}
}

export default productsState
